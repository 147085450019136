/* You can add global styles to this file, and also import other style files */
@import '~@fortawesome/fontawesome-free/css/all.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.dashboard .mat-tab-header {
  /* border: 2px solid #c8c8c8 !important;
  border-radius: 50rem !important; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
}

.dashboard .mat-tab-label {
  color: #000000;
  background-color: #9889a4;
  opacity: 0.2;
  /* border-radius: 50rem !important; */
}
.dashboard .mat-tab-label-active {
  color: #ffffff;
  background-color: #724e8c;
  opacity: 1 !important;
  /* border-radius: 50rem !important; */
}

@media (max-width: 599px) {
  .dashboard .mat-tab-label {
    min-width: 0px;
  }
}

.dashboard .mat-tab-label {
  height: 28px;
  padding: 0 6px;
}

.urjaa_VP .mat-tab-label {
  color: rgba(0, 0, 0, 0.6);
  background-color: #ffffff;
}
.urjaa_VP .mat-tab-label-active {
  color: #724e8c;
  background-color: #ffffff;
  opacity: 1 !important;
}

.modal-content {
  border-radius: 1rem;
}
.bg_sec {
  background-color: #eaab5e!important;
}

.bg_pri {
  background-color: #f4d5ae!important;
}
.Period_Cust
  {
  width: 87px;
  height: 29px;
  font-size: 10px;
  margin-left: -5px;
  font-family:  Roboto;
  }


:host ::ng-deep .mat-form-field-appearance-outline .mat-form-field-flex   {
  /* border-radius: 25px; */

  font-size: 11px;
  font-weight: 400;
  line-height: 0.7;
  padding: 0 .75em 0 .75em !important;
  margin-top: -0.25em !important;;
  margin-bottom: -0.25em !important;;
  position: relative !important;;
  height: 33px !important;;

}
:host ::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix  {
  /* border-radius: 25px; */
   padding: 0em .75em 0 .75em;
  font-size: 11px;
  font-weight: 400;
  line-height: 0.7;
  padding: 0 0 0 0 !important;
   margin-top: 0.75em !important;
  margin-bottom: -0.25em !important;
  position: relative !important;
  height: 33px !important;

}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #b49fc2;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #724e8c;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #724e8c;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #724e8c;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.filter_text
  {
    color: #724e8c;
    font-family: Roboto;
    font-weight:500;
    font-size: 0.875rem;
  }

  .mediaTab .mat-tab-label {
    opacity: 0.6;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 1.25px;
  }
  .mediaTab .mat-tab-label-active {
    color: #ffffff;
    background-color: #724e8c;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .word_break{
    word-break: break-word;
}
